import Paper from '@material-ui/core/Paper';
import { makeStyles, MuiThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import React, { createRef, useEffect } from 'react';
import { getDateInString, getName, getOpenDateSince, getScheduleType, getVisitName, toCamelCase } from '../../../utils/caseDetailsFormat';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
}));

const getMuiTheme = () => unstable_createMuiStrictModeTheme({
    overrides: {
        MUIDataTableBodyRow: {
        root: {
            cursor: 'pointer'
        }
      }
    }
  })

export default function StickyHeadTable({ cases, caseStatus, onClickRow }) {
    const classes = useStyles();
    const isPending = caseStatus === 'pending';
    const isAssigned = caseStatus === 'assigned';
    const isClosed = caseStatus === 'closed';
    const tableRef = createRef();
    useEffect(() => {
        if (caseStatus) {
            tableRef.current.changePage(0);
        }
    }, [caseStatus, tableRef])


    const columns = [
        { name: 'caseId', id: 'caseId', label: 'Case Id', minWidth: 40 },
        { name: 'name', id: 'name', label: 'Name', minWidth: 200 },
        { name: 'member', id: 'member', label: 'Patient', minWidth: 200 },
        {
            name: 'provider', id: 'provider', label: 'Provider', options: {
                filter: true,
                display: isAssigned || isClosed
            }
        },
        { name: 'visitType', id: 'visitType', label: 'Visit Type', minWidth: 40 },
        { name: 'caseType', id: 'caseType', label: 'Case Type', minWidth: 40 },
        {
            name: 'openDate', id: 'openDate', label: 'Open Date', options: {
                filter: true,
                customBodyRenderLite: (dataIndex) => {
                    if (isPending || isAssigned) {
                        if (getDateForTable()[dataIndex].closeDate) {
                            return `${getOpenDateSince(getDateForTable()[dataIndex].openDate)} (Reopened)`
                        } else {
                            return getOpenDateSince(getDateForTable()[dataIndex].openDate)
                        }
                    } else {
                        return getDateInString(getDateForTable()[dataIndex].openDate)
                    }
                }
            }
        }, {
            name: 'closeDate', id: 'closeDate', label: 'Close Date', options: {
                filter: true,
                display: isClosed,
                customBodyRenderLite: (dataIndex) => {
                    return getDateInString(getDateForTable()[dataIndex].closeDate)
                }
            }
        },
    ];

    const getDateForTable = () => {
        return cases.filter(caseItem => caseItem.status === caseStatus).map((row) => {
            return {
                caseId: row.caseId,
                name: row.name,
                member: getName(row.member),
                provider: getName(row.provider),
                visitType: getScheduleType(row),
                caseType: getVisitName(row.visitType),
                openDate: row.openDate,
                closeDate: row.closeDate
            }
        })
    }
    const options = {
        selectableRows: 'single',
        rowsPerPage: 10,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        sortOrder: {
            name: 'openDate',
            direction: 'desc',
        },
        onRowClick: (rowData) => {
            onClickRow(true, rowData[0])
        },
    };
    return (
        <Paper className={classes.root}>
            <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                innerRef={tableRef}
                title={`${toCamelCase(caseStatus)} Cases `}
                data={getDateForTable()}
                columns={columns}
                options={options}
            />
            </MuiThemeProvider>
        </Paper>
    );
}
