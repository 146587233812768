import formUrlencoded from 'form-urlencoded';
import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { logout, signInSubmitFailure, signInSubmitRequest, signInSubmitSuccess } from '../actions/auth';
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../utils/request';
import { request } from './request';

export function *handleLoginSuccess(url: string, method: string): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  yield put(signInSubmitSuccess(response));
}

export function *handleLoginFail(url: string, method: string): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  const {message=""} = response
  yield put(signInSubmitFailure(message));
}

export function* signInSubmitRequestHandler(
  { payload: { email, password }}: { payload: { email: string, password: string }}
): Iterable<*> {
  const method = 'POST';
  const url = '/auth/authenticate/insighter';
  yield fork(
    request,
    url,
    method,
    {
      body: formUrlencoded({
        grant_type: 'password',
        password: password,
        username: email.toLowerCase(),
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: 'read write',
        user_type: process.env.REACT_APP_APP_TYPE,
        is_web:true
      })
    }
  );

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleLoginSuccess, url, method),
    fail: call(handleLoginFail, url, method)
  });
}

export function* logoutHandler() {
  yield call(()=>{console.log('Log Out Successfull')})
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export default function* helloSaga() {
  yield takeEvery(logout, logoutHandler)
  yield takeEvery(signInSubmitRequest, signInSubmitRequestHandler)
  
}