import { take, put, call, fork, race, takeLatest } from "redux-saga/effects";

import {
  deleteCampaignUsersRequest,
  deleteCampaignUsersSuccess,
  deleteCampaignUsersFailure,
} from "../../actions/campaign";

import formUrlencoded from "form-urlencoded";
import { request } from "..//request";

/* @flow */
import {
  startRequestPattern,
  succeededRequestPattern,
  failedRequestPattern,
} from "../../utils/request";

export function* handleDeleteCampaignUsersSuccess(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  yield put(deleteCampaignUsersSuccess(response.data));
}

export function* handleDeleteCampaignUsersFailure(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  const { message = "" } = response || {};
  yield put(deleteCampaignUsersFailure(message));
}

export function* deleteCampaignUsersRequestHandler({
  payload: { campaignId },
}: {
  payload: { campaignId: any },
}): Iterable<*> {
  const method = "DELETE";
  const url = `/pinpoint/v1/campaign/${campaignId}/users`;
  yield fork(request, url, method);

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleDeleteCampaignUsersSuccess, url, method),
    fail: call(handleDeleteCampaignUsersFailure, url, method),
  });
}

export default function* deleteCampaignUsersSaga() {
  yield takeLatest(
    deleteCampaignUsersRequest,
    deleteCampaignUsersRequestHandler
  );
}
