import { all } from 'redux-saga/effects';
import assignProvider from './assignProvider';
import changeProvider from './changeProvider';
import getCaseCount from './getCaseCount';
import getCases from './getCases';
import reopenCase from './reopenCase';
import unassignProvider from './unassignProvider';

export default function* saga() {
  yield all([
  assignProvider(),
  unassignProvider(),
  changeProvider(),
  getCaseCount(),
  getCases(),
  reopenCase()
]);
}
