import formUrlencoded from 'form-urlencoded';
import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { assignProviderFailure, assignProviderRequest, assignProviderSuccess } from '../../actions/cases/assignProvider';
import { updateCaseCount } from '../../actions/cases/caseCount';
import { updateCaseDetails } from '../../actions/cases/cases';
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../../utils/request';
import { request } from '../request';

export function* handleAssignProviderSuccess(url: string, method: string): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  yield put(assignProviderSuccess(response.data));
  yield put(updateCaseDetails(response.data));
  yield put(updateCaseCount({to:response.data.status, from:'pending'}));
}

export function* handleAssignProviderFail(url: string, method: string): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  const {  message = "" } = response || {}
  yield put(assignProviderFailure(message));
}

export function* assignProviderRequestHandler(
  { payload: {caseId, providerId } }
) {
  const method = 'PUT';
  const url = `/user/v2/case/${caseId}/assignprovider`;
  yield fork(
    request,
    url,
    method,
    {
      body: formUrlencoded({
        providerId
      })
    }
  );

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleAssignProviderSuccess, url, method),
    fail: call(handleAssignProviderFail, url, method)
  });
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export default function* patientSaga() {
  yield takeEvery(assignProviderRequest, assignProviderRequestHandler)

}