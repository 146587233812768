import { handleActions } from 'redux-actions';
import { changeProviderFailure, changeProviderRequest, changeProviderSuccess } from '../../actions/cases/changeProvider';

export default handleActions({
  [changeProviderRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [changeProviderSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, result: action.payload })
  },
  [changeProviderFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload }),
}, { result: null, isFetching: null, error: null });