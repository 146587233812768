import Paper from '@material-ui/core/Paper';
import { makeStyles, MuiThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import React, { createRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getDateInString } from '../../../utils/caseDetailsFormat';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const getMuiTheme = () => unstable_createMuiStrictModeTheme({
    overrides: {
        MUIDataTableBodyRow: {
            root: {
                cursor: 'pointer'
            }
        }
    }
})

 function PatientList({ patients, caseStatus }) {
    const classes = useStyles();

    const tableRef = createRef();
    useEffect(() => {
        if (caseStatus) {
            tableRef.current.changePage(0);
        }
    }, [caseStatus, tableRef])

    const history = useHistory();
    const openPatient = (selectedPatientId) => {
        history.push(`/patients/${selectedPatientId}/cases`)
    };
    const columns = [
        { label: 'UserId', name: 'userId' },
        { label: 'First Name', name: 'firstName' },
        { label: 'Last Name', name: 'lastName' },
        { label: 'Gender', name: 'gender' },
        {
            label: 'Birth Date', name: 'birthDate', options: {
                customBodyRenderLite: (dataIndex) => {
                    return getDateInString(getDateForTable()[dataIndex].birthDate)
                }
            }
        },
        { label: 'Country', name: 'country' },
        { label: 'State', name: 'state' },

    ];

    const getDateForTable = () => {
        return patients.map((row) => {
            return {
                userId: row.userId,
                firstName: row.firstName,
                lastName: row.lastName,
                gender: row.gender,
                birthDate: row.birthDate,
                country: row.country,
                state: row.state,
            }
        })
    }
    const options = {
        selectableRows: 'single',
        rowsPerPage: 10,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        sortOrder: {
            name: 'userId',
            direction: 'desc',
        },
        onRowClick: (rowData) => {
            openPatient(rowData[0])
        }
    };
    return (
        <Paper className={classes.root}>
            <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                innerRef={tableRef}
                title={`Patients`}
                data={getDateForTable()}
                columns={columns}
                options={options}
            />
            </MuiThemeProvider>
        </Paper>
    );
}
const enhance = connect(
    (state) => {
        return { patients: state.patients.patients }
    },
)

export default enhance(PatientList);
