import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router";
import {
  CampaignDetails,
  CampaignList,
  CampaignUsersList,
} from "../components/Campaign";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function CampaignDetailsPage({ match }) {
  const classes = useStyles();
  const campaignId = match.params.campaignId;
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid container spacing={3} >
          <Grid item xs={3} >
            <Paper className={classes.paper} style={{ height: "100%" }}>
              <CampaignList />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <CampaignDetails campaignId={campaignId} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <CampaignUsersList campaignId={campaignId} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default withRouter(CampaignDetailsPage);
