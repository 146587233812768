import { createAction } from 'redux-actions';

 
  export const assignProviderRequest = createAction(
    'ASSIGN_PROVIDER_REQUEST',
    values => values
  );
  
  export const assignProviderSuccess = createAction(
    'ASSIGN_PROVIDER_SUCCESS',
    values => values
  );

  export const assignProviderFailure = createAction(
    'ASSIGN_PROVIDER_FAILURE',
    values => values
  );
  
  
  