import addSeconds from 'date-fns/addSeconds';
import { handleActions } from 'redux-actions';
import { logout, signInSubmitFailure, signInSubmitRequest, signInSubmitSuccess, updatedToken } from '../actions/auth';

export default handleActions({
  [logout]: (state) => {
    return Object.assign({},state, { auth: null, error: false})
  },
  [signInSubmitRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [signInSubmitSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, auth: {...action.payload, expire_date: addSeconds(new Date(), action.payload.expires_in)} })
  },
  [updatedToken]: (state, action) => {
    return Object.assign({},state, {  auth: {...action.payload, expire_date: addSeconds(new Date(), action.payload.expires_in)} })
  },
  [signInSubmitFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload }),
}, { auth: null, appClientId: '', isFetching: null, error: null });