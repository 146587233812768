import { createAction } from 'redux-actions';

export const addCampaignUsersRequest: any= createAction(
'ADD_CAMPAIGN_USERS_REQUEST',
values => values
);

export const addCampaignUsersSuccess: any = createAction(
'ADD_CAMPAIGN_USERS_SUCCESS',
values => values
);

export const addCampaignUsersFailure: any= createAction(
'ADD_CAMPAIGN_USERS_FAILURE',
values => values
);

export const addCampaignUsersReset: any = createAction(
'ADD_CAMPAIGN_USERS_RESET',
values => values
);