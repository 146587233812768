import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Cases } from '../../Cases';
import CaseStatusSelector from '../../Cases/CaseStatusSelector';
import DateRangeSelection from '../../DateRangeSelection';
import ProvidersDetails from '../ProvidersDetails';
const useStyles = makeStyles((theme) => ({
    container: {
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

export default function AllCases({ providerId, memberId }) {
    const classes = useStyles();
    const defaultCaseStatus = providerId ? 'assigned' : 'pending'
    const [caseStatus, setCaseStatus] = React.useState(defaultCaseStatus);
    const [dateRange, setDateRange] = React.useState({ startDate: null, endDate: null });
    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} md={12} lg={12}>
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container spacing={2} className={classes.container}>
                            <Grid item xs={12} md={4} lg={4}>
                                <Paper className={classes.paper} >
                                    <Grid container spacing={2} className={classes.container}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <CaseStatusSelector setCaseStatus={setCaseStatus} caseStatus={caseStatus} providerId={providerId} memberId={memberId} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <DateRangeSelection dateRange={dateRange} setDateRange={setDateRange} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <ProvidersDetails providerId={providerId} />
                            </Grid>

                        </Grid>
                    </Container>

                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Cases caseStatus={caseStatus} providerId={providerId} memberId={memberId} dateRange={dateRange} />
                </Grid>
            </Grid>
        </Container>
    );
}