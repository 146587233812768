import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Login from '../components/Login';
import { Logo } from '../components/Logo';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(4),
		height: '60px'
	},
}));

export default function SignInSide(props) {
	const classes = useStyles();

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Logo styles={classes.avatar}/>
					<Typography component="h1" variant="h5">
						Admin
          </Typography>
		  <Typography component="h1" variant="h6">
						Sign in
          </Typography>
					<Box mt={5}>
						<Login />
					</Box>
				</div>
			</Grid>
		</Grid>
	);
}