import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCasesRequest } from '../../../actions/cases/cases';
import CaseDetails from '../CaseDetails';
import CasesTable from '../CasesTable';
const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop: theme.spacing(4),
    }
}));

function CaseList({ cases, isFetching, getCasesRequest, caseStatus, providerId, memberId, dateRange }) {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [selectedCaseId, setSelectedCaseId] = React.useState(null);
    useEffect(() => {
        if (caseStatus) {
            getCasesRequest({ caseStatus, providerId, memberId, dateRange })
        }
    }, [caseStatus, getCasesRequest, providerId, memberId, dateRange])
    const toggleDrawer = (open, selectedCaseId) => {
        setOpenDrawer(open);
        setSelectedCaseId(selectedCaseId)
    };
    const loadingComponent = (
        <div style={{ position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)' }}>
            <CircularProgress size={24} />
        </div>
    );
    return (
        <Container maxWidth={false} className={classes.container}>
            <div style={{ position: 'relative' }}>
                {isFetching && loadingComponent}
                <CasesTable cases={cases} caseStatus={caseStatus} onClickRow={toggleDrawer} />
            </div>
            <Drawer anchor={'right'} open={openDrawer} onClose={() => toggleDrawer(false)}>
                {selectedCaseId && <CaseDetails caseId={selectedCaseId} />}
            </Drawer>
        </Container>
    );
}
const enhance = connect(
    (state) => {
        return { cases: state.cases.cases, isFetching: state.cases.isFetching }
    },
    { getCasesRequest }
)

export default enhance(CaseList);
