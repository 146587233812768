/* @flow */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

const CampaignScreen = (props: Object) => {
  const classes = useStyles();
  const { value } = props;

  return <div> CampaignScreen </div>;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(CampaignScreen);
