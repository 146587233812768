import { createAction } from 'redux-actions';

export const syncCampaignUsersRequest: any= createAction(
'SYNC_CAMPAIGN_USERS_REQUEST',
values => values
);

export const syncCampaignUsersSuccess: any = createAction(
'SYNC_CAMPAIGN_USERS_SUCCESS',
values => values
);

export const syncCampaignUsersFailure: any= createAction(
'SYNC_CAMPAIGN_USERS_FAILURE',
values => values
);

export const syncCampaignUsersReset: any = createAction(
'SYNC_CAMPAIGN_USERS_RESET',
values => values
);