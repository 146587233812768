import { createAction } from 'redux-actions';

 
  export const unassignProviderRequest = createAction(
    'UNASSIGN_PROVIDER_REQUEST',
    values => values
  );
  
  export const unassignProviderSuccess = createAction(
    'UNASSIGN_PROVIDER_SUCCESS',
    values => values
  );

  export const unassignProviderFailure = createAction(
    'UNASSIGN_PROVIDER_FAILURE',
    values => values
  );
  
  
  