import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import Moment from 'moment';
import React from 'react';


const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'space-between',
	},
	paper: {
		padding: '0 8px',
		cursor: 'pointer',
		display: 'flex',
	},
	formControl: {
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	margin: {
		margin: theme.spacing(1),
	},
	textField: {
		flexGrow: 1,
		maxWidth: 160
	},
}));

function DateRange({ dateRange, setDateRange }) {
	const classes = useStyles();
	const [dateFilterType, setDateFilterType] = React.useState('all')
	const [values, setValues] = React.useState({
		startDate: dateRange.startDate ? Moment(dateRange.startDate).format('MM/DD/YYYY') : null,
		endDate: dateRange.endDate ? Moment(dateRange.endDate).format('MM/DD/YYYY') : null,
	});

	const handleStartDateChange = date => {
		if (date) {
			setValues({ startDate: date.format('MM/DD/YYYY'), endDate: null });
		}
	};
	const handleEndDateChange = date => {
		if (date) {
			setValues({ startDate: values.startDate, endDate: date.format('MM/DD/YYYY') });
		}
	};
	const handleEndDateClose = () => {
		console.log(values)
		setDateRange(values)
	};
	const handleDateSelectChange = (value) => {
		setDateFilterType(value)
		console.log(value)
		if (value === 'custom') {
			return
		}
		let newDateRange = {}

		if (value === 'all' ){
			newDateRange = { startDate: null, endDate: null }
			setValues(newDateRange);
			setDateRange(newDateRange)
		}
		const startOfMonth = Moment().startOf('month').format('MM/DD/YYYY');
		const endOfMonth = Moment().endOf('month').format('MM/DD/YYYY');

		if (value === 'thisMonth') {
			newDateRange = { startDate: startOfMonth, endDate: endOfMonth }
		}
		if (value === 'lastMonth') {
			const startOfLastMonth = Moment(startOfMonth).subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
			const endOfLastMonth = Moment(endOfMonth).subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
			newDateRange = { startDate: startOfLastMonth, endDate: endOfLastMonth }
		}
		console.log(newDateRange)
		setValues(newDateRange);
		setDateRange(newDateRange)
	};

	return (
			<Grid container spacing={4}>
				<Grid item xs={12} md={4} lg={4}>
					<FormControl margin="normal" className={classes.formControl}>
						<InputLabel id="case-status-selector-label">Filter By Date</InputLabel>
						<Select
							labelId="case-status-selector-label"
							id="case-status-selector"
							value={dateFilterType}
							onChange={(event) => { handleDateSelectChange(event.target.value) }}
						>
							<MenuItem value={'all'}>All</MenuItem>
							<MenuItem value={'thisMonth'}>This Month</MenuItem>
							<MenuItem value={'lastMonth'}>Last Month</MenuItem>
							<MenuItem value={'custom'}>Custom</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<DatePicker
							disabled={dateFilterType !== 'custom'}
							autoOk={true}
							emptyLabel={'Select'}
							margin="normal"
							variant="inline"
							format="MM/DD/yyyy"
							label="From"
							value={values.startDate}
							onChange={handleStartDateChange}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<DatePicker
							emptyLabel={'Select'}
							disabled={dateFilterType !== 'custom'}
							margin="normal"
							variant="inline"
							format="MM/DD/yyyy"
							label="To "
							onClose={handleEndDateClose}
							value={values.endDate}
							onChange={handleEndDateChange}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
			</Grid>
	);
}

export default DateRange