// login.js

// Material UI components
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { logout } from '../../actions/auth';
import { LogoIcon } from '../Logo';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	list: {
		width: 300,
	},
	root: {
		display: 'flex',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1000,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButtonHidden: {
		display: 'none',
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
	avatar: {
		height: '32px',
		marginRight: theme.spacing(1),
	},
}));

const NavBar = (props) => {
	const classes = useStyles();
	const isSignedIn = props.auth
	const history = useHistory();
	return (
		<AppBar position="fixed" className={clsx(classes.appBar)}>
			<Toolbar className={classes.toolbar} variant="dense">
				<LogoIcon styles={classes.avatar} />
				<Typography component="h1" variant="subtitle1" color="inherit" noWrap className={classes.title}>
					<span>		Admin </span>
					<span>	<Typography component="h1" variant="caption" color="inherit" noWrap className={classes.title}>
						v0.1.4 </Typography> </span>
				</Typography>

				{isSignedIn && (

					<Button
						color="inherit"
						size="small"
						onClick={() => props.logout()}
						className={classes.button}
						endIcon={<ExitToAppIcon />}
					>
						Logout
					</Button>
				)}
				{!isSignedIn && <Button color="inherit" onClick={() => history.push("/login")}>Login</Button>}
			</Toolbar>
		</AppBar>
	);
}

const enhance = connect(
	(state) => {
		return { auth: state.auth.auth }
	},
	{
		logout
	}
)

export default enhance(NavBar);