import { createAction } from 'redux-actions';

export const createCampaignRequest: any= createAction(
'CREATE_CAMPAIGN_REQUEST',
values => values
);

export const createCampaignSuccess: any = createAction(
'CREATE_CAMPAIGN_SUCCESS',
values => values
);

export const createCampaignFailure: any= createAction(
'CREATE_CAMPAIGN_FAILURE',
values => values
);

export const createCampaignReset: any = createAction(
'CREATE_CAMPAIGN_RESET',
values => values
);