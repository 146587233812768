import { take, put, call, fork, race, takeLatest } from "redux-saga/effects";

import {
  syncCampaignUsersRequest,
  syncCampaignUsersSuccess,
  syncCampaignUsersFailure,
} from "../../actions/campaign";

import formUrlencoded from "form-urlencoded";
import { request } from "..//request";

/* @flow */
import {
  startRequestPattern,
  succeededRequestPattern,
  failedRequestPattern,
} from "../../utils/request";

export function* handleSyncCampaignUsersSuccess(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  yield put(syncCampaignUsersSuccess(response.data));
}

export function* handleSyncCampaignUsersFailure(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  const { message = "" } = response || {};
  yield put(syncCampaignUsersFailure(message));
}

export function* syncCampaignUsersRequestHandler({
  payload: { campaignId },
}: {
  payload: { campaignId: any },
}): Iterable<*> {
  const method = "POST";
  const url = `/pinpoint/v1/campaign/${campaignId}/sync`;
  yield fork(request, url, method);

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleSyncCampaignUsersSuccess, url, method),
    fail: call(handleSyncCampaignUsersFailure, url, method),
  });
}

export default function* syncCampaignUsersSaga() {
  yield takeLatest(syncCampaignUsersRequest, syncCampaignUsersRequestHandler);
}
