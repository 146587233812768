import { take, put, call, fork, race, takeLatest } from "redux-saga/effects";

import {
  getCampaignUsersRequest,
  getCampaignUsersSuccess,
  getCampaignUsersFailure,
} from "../../actions/campaign";

import formUrlencoded from "form-urlencoded";
import { request } from "../request";

/* @flow */
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../../utils/request';

export function* handleGetCampaignUsersSuccess(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  yield put(getCampaignUsersSuccess(response.data));
}

export function* handleGetCampaignUsersFailure(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  const { message = "" } = response || {};
  yield put(getCampaignUsersFailure(message));
}

export function* getCampaignUsersRequestHandler({
  payload: { campaignId },
}: {
  payload: { campaignId: any },
}): Iterable<*> {
  const method = "GET";
  const url = `/pinpoint/v1/campaign/${campaignId}/users`;
  yield fork(request, url, method);

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleGetCampaignUsersSuccess, url, method),
    fail: call(handleGetCampaignUsersFailure, url, method),
  });
}

export default function* getCampaignUsersSaga() {
  yield takeLatest(getCampaignUsersRequest, getCampaignUsersRequestHandler);
}
