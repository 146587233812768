import { handleActions } from 'redux-actions';
import { getPatientsFailure, getPatientsRequest, getPatientsSuccess } from '../actions/patients';

export default handleActions({
  [getPatientsRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [getPatientsSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, patients: action.payload })
  },
  [getPatientsFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload , patients:[]}),
}, { patients: [], isFetching: null, error: null });