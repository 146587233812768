/* @flow */
import { handleActions } from "redux-actions";
import {
getCampaignDetailsRequest,
getCampaignDetailsSuccess,
getCampaignDetailsFailure,
getCampaignDetailsReset,
} from "../../../actions/campaign";

export default handleActions(
{
[getCampaignDetailsReset]: () => ({ result: null, isFetching: null, error: null }),
[getCampaignDetailsRequest]: (state) => {
return Object.assign({}, state, { isFetching: true, error: null });
},
[getCampaignDetailsSuccess]: (state, action) =>
Object.assign({}, state, { isFetching: false, result: action.payload, error: null }),
[getCampaignDetailsFailure]: (state, action) =>
Object.assign({}, state, { isFetching: false, error: action.payload, result:null }),
},
{ result: null, isFetching: null, error: null }
);