/* @flow */
import _ from 'lodash';
import { call, fork, put, race, take, takeLatest } from 'redux-saga/effects';
import { getCaseCountFailure, getCaseCountRequest, getCaseCountSuccess } from '../../actions/cases/caseCount';
import { failedRequestPattern, succeededRequestPattern } from '../../utils/request';
import { request } from '../request';


export function* getCaseCountSucceeded(url: string): Iterable<*> {
  const action = yield take(succeededRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getCaseCountSuccess(data));
}

export function* getCaseCountFailed(url: string): Iterable<*> {
  const action = yield take(failedRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getCaseCountFailure(data || 'unable to load case Count'));
}

export function* getCaseCountRequestSaga({payload}): Iterable<*> {
  const {providerId,memberId,dateRange } = payload
  let url = '/case/v2/case/count';
  if(providerId){
    url = `/case/v2/case/count?providerId=${providerId}`;
  }else if(memberId){
    url = `/case/v2/case/count?memberId=${memberId}`;
  }
  if (dateRange?.startDate) {
    url = `${url}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  }

  yield fork(request, url);

  yield race({
    success: call(getCaseCountSucceeded, url),
    fail: call(getCaseCountFailed, url)
  });
}

export default function* loadServiceSaga(): Iterator<*> {
  yield takeLatest(String(getCaseCountRequest), getCaseCountRequestSaga);
}
