import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPatientDetailsRequest } from '../../../actions/patients';
import { getOpenDateSince, getDateInString } from '../../../utils/caseDetailsFormat';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        position:'relative',
        minHeight:200,
        flexGrow: 1,
        flexDirection: 'column',
    },
    active: {
        backgroundColor: 'lightgrey'
    },
    detailsRow: {
        margin: '8px 0'
    }
}));

const PatientDetails = ({ patient, patientId,getPatientDetailsRequest,isFetching }) => {
    const classes = useStyles();
    useEffect(() => {
        getPatientDetailsRequest(patientId)
    }, [getPatientDetailsRequest,patientId])
    const loadingComponent = (
        <div style={{ position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)' }}>
            <CircularProgress size={24} />
        </div>
    );
    return <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.paper} >
            <Typography component="h1" variant="body1">
                <b>Patient Details</b>
            </Typography>
            <Divider light style={{ margin: '8px 0' }} />
        
            {isFetching && loadingComponent}
            {!isFetching && <Box display='flex' flexGrow='1' flexDirection='column' justifyContent='flex-start' alignItems='stretch' >
                <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
                <TextField
                        fullWidth
                        label="User Id"
                        value={patient.userId}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="First Name"
                        value={patient.firstName}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Last Name"
                        value={patient.lastName}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Gender"
                        value={patient.gender}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Birth Date"
                        value={getDateInString(patient.birthDate) }
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
          
   
                </Box>
                <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >

                <TextField
                        fullWidth
                        label="Created On"
                        value={getOpenDateSince(patient.createdAt)}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
    
                                 <TextField
                        fullWidth
                        label={`Country & State`}
                        value={`${patient.country?.toUpperCase()} - ${patient.state}`}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                
                      <TextField
                        fullWidth
                        label="Phone"
                        value={patient.phoneNumber || 'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                                <TextField
                        fullWidth
                        label="Email"
                        value={patient.email || 'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                                                         <TextField
                        fullWidth
                        label=""
                        value={''}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                </Box>
                <br></br>
                <Typography component="h1" variant="body2">
                <b>Subscription Details</b>
            </Typography>
            <Divider light style={{ margin: '8px 0' }} />
        
                <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
                <TextField
                        fullWidth
                        label="Is Subscribed"
                        value={patient.isSubscribed ? 'Yes':'No' }
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Plan Name"
                        multiline={true}
                        value={patient.plan?.displayName ||  'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Subscription Status"
                        value={patient.subscription?.status || 'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                        <TextField
                        fullWidth
                        label="Activated on"
                        value={(patient.subscription?.activated_at)?getOpenDateSince(new Date(patient.subscription?.activated_at*1000)): 'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Due Invoice Count"
                        value={patient.subscription?.due_invoices_count}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                </Box>
                <br></br>
                <Typography component="h1" variant="body2">
                <b>Insurance Details</b>
            </Typography>
            <Divider light style={{ margin: '8px 0' }} />
            <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
                <TextField
                        fullWidth
                        label="Is Medicare Member"
                        value={patient.isMedicareMember ? 'Yes':'No' }
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
            
                    <TextField
                        fullWidth
                        label="Medicare Number"
                        value={patient.medicareNumber || 'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
         <TextField
                        fullWidth
                        label="Medicare Status"
                        value={(patient.medicareNumber && patient.medicareNumberStatus)|| 'NA'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                            <TextField
                        fullWidth
                        label=""
                        value={''}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                                       <TextField
                        fullWidth
                        label=""
                        value={''}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                </Box>
            </Box >}
        </Paper>
    </Container>
}


const enhance = connect(
    (state) => {
        return { patient: state.patientDetails.patientDetails, isFetching: state.patientDetails.isFetching }
    },
    { getPatientDetailsRequest }
)

export default enhance(PatientDetails);
