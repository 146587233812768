import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Cases } from '../../Cases';
import CaseStatusSelector from '../../Cases/CaseStatusSelector';
import DateRangeSelection from '../../DateRangeSelection';
import PatientDetails from '../PatientDetails';
const useStyles = makeStyles((theme) => ({
    container: {
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
}));

export default function AllCases({ patientId }) {
    const classes = useStyles();
    const defaultCaseStatus = 'pending'
    const [caseStatus, setCaseStatus] = React.useState(defaultCaseStatus);
    const [dateRange, setDateRange] = React.useState({ startDate: null, endDate: null });
    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} md={12} lg={12}>
                    <PatientDetails patientId={patientId} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Container maxWidth={false} className={classes.container}>
                        <Paper className={classes.paper} >
                            <Typography component="h1" variant="body1">
                                <b>Patient Cases</b>
                            </Typography>
                            <Divider light style={{ margin: '8px 0' }} />

                            <Grid container spacing={0} className={classes.container}>
                                <Grid item xs={12} md={2} lg={2}>
                                    <CaseStatusSelector setCaseStatus={setCaseStatus} caseStatus={caseStatus} patientId={patientId} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <DateRangeSelection dateRange={dateRange} setDateRange={setDateRange} />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Cases caseStatus={caseStatus} memberId={patientId} dateRange={dateRange} />
                </Grid>
            </Grid>
        </Container>
    );
}