/* @flow */
import { handleActions } from "redux-actions";
import {
  createCampaignRequest,
  createCampaignSuccess,
  createCampaignFailure,
  createCampaignReset,
} from "../../../actions/campaign";

export default handleActions(
  {
    [createCampaignReset]: () => ({
      result: null,
      isFetching: null,
      error: null,
    }),
    [createCampaignRequest]: (state) => {
      return Object.assign({}, state, { isFetching: true, error: null });
    },
    [createCampaignSuccess]: (state, action) =>
      Object.assign({}, state, {
        isFetching: false,
        result: action.payload,
        error: null,
      }),
    [createCampaignFailure]: (state, action) =>
      Object.assign({}, state, {
        isFetching: false,
        error: action.payload,
        result: null,
      }),
  },
  { result: null, isFetching: null, error: null }
);
