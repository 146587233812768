import { handleActions } from 'redux-actions';
import { getPatientDetailsFailure, getPatientDetailsRequest, getPatientDetailsSuccess } from '../actions/patients';

export default handleActions({
  [getPatientDetailsRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [getPatientDetailsSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, patientDetails: action.payload })
  },
  [getPatientDetailsFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload , patientDetails:{}}),
}, { patientDetails: [], isFetching: null, error: null });