import { createAction } from 'redux-actions';

export const getCampaignsRequest: any= createAction(
'GET_CAMPAIGNS_REQUEST',
values => values
);

export const getCampaignsSuccess: any = createAction(
'GET_CAMPAIGNS_SUCCESS',
values => values
);

export const getCampaignsFailure: any= createAction(
'GET_CAMPAIGNS_FAILURE',
values => values
);

export const getCampaignsReset: any = createAction(
'GET_CAMPAIGNS_RESET',
values => values
);