import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getProvidersRequest } from '../../../actions/providers';
import { default as ProvidersTable } from '../ProvidersTable';
const useStyles = makeStyles((theme) => ({
    container: {
    }
}));

function ProviderList({ providers, isFetching, getProvidersRequest }) {
    const classes = useStyles();
    const history = useHistory();
    // const [openDrawer, setOpenDrawer] = React.useState(false);
    // const [selectedProviderId, setSelectedProviderId] = React.useState(null);
    useEffect(() => {
        getProvidersRequest()
    }, [getProvidersRequest])
    const toggleDrawer = (open, selectedProviderId) => {
        history.push(`/providers/${selectedProviderId}/cases`)
        // setOpenDrawer(open);
        // setSelectedProviderId(selectedProviderId)
    };
    const loadingComponent = (
        <div style={{ position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)' }}>
            <CircularProgress size={24} />
        </div>
    );
    return (
        <Container maxWidth={false} className={classes.container}>
            <div style={{ position: 'relative' }}>
                {isFetching && loadingComponent}
                <ProvidersTable providers={providers} onClickRow={toggleDrawer} />
            </div>
            {/* <Drawer anchor={'right'} open={openDrawer} onClose={() => toggleDrawer(false)}>
                {selectedProviderId && <ProviderDetails caseId={selectedProviderId} />}
            </Drawer> */}
        </Container>
    );
}
const enhance = connect(
    (state) => {
        return { providers: state.providers.providers, isFetching: state.providers.isFetching }
    },
    { getProvidersRequest }
)

export default enhance(ProviderList);
