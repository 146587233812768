import { createAction } from 'redux-actions';
export const logout = createAction(
    'lOGOUT',
    values => values
  );

  export const signInSubmitRequest = createAction(
    'SIGN_IN_SUBMIT_REQUEST',
    values => values
  );
  
  export const signInSubmitSuccess = createAction(
    'SIGN_IN_SUBMIT_SUCCESS',
    values => values
  );

  export const signInSubmitFailure = createAction(
    'SIGN_IN_SUBMIT_FAILURE',
    values => values
  );
  

  export const updatedToken = createAction(
    'SIGN_IN_UPDATED_TOKEN',
    values => values
  );
  


  