import { createAction } from 'redux-actions';

export const getCampaignUsersRequest: any= createAction(
'GET_CAMPAIGN_USERS_REQUEST',
values => values
);

export const getCampaignUsersSuccess: any = createAction(
'GET_CAMPAIGN_USERS_SUCCESS',
values => values
);

export const getCampaignUsersFailure: any= createAction(
'GET_CAMPAIGN_USERS_FAILURE',
values => values
);

export const getCampaignUsersReset: any = createAction(
'GET_CAMPAIGN_USERS_RESET',
values => values
);