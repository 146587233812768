import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { unassignProviderRequest } from '../../../actions/cases/unassignProvider';

function UnassignProviderView(props) {
    const caseId = props.caseId;
    const handleClickOpen = () => {
        props.unassignProviderRequest({caseId});
    };
    return (
        <div>
            {props.isFetching && <CircularProgress size={24} />}
            {!props.isFetching && <Button disabled={!caseId}  variant="contained" color="primary" onClick={handleClickOpen}>
                Unassign Provider
            </Button>}
        </div>
    );
}
const enhance = connect(
    (state) => {
        return { unassignProviderSuccess: state.unassignProvider.result, isFetching: state.unassignProvider.isFetching, error: state.unassignProvider.error }
    },
    { unassignProviderRequest }
)

export default enhance(UnassignProviderView);
