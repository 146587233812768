import { Button, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { signInSubmitRequest } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '50ch',
	},
}));

function Signin(props) {
	const classes = useStyles();
	const [values, setValues] = React.useState({
		email: '',
		password: '',
		showPassword: false,
	});

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleClickSubmit = () => {
		// console.log(values)
		props.signInSubmitRequest(values)
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<div className={classes.root}>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<TextField
					value={values.email}
					onChange={handleChange('email')}
					error={false}
					id="email-text"
					label="Email"
					variant="outlined"
					helperText=""
				/>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? 'text' : 'password'}
					value={values.password}
					onChange={handleChange('password')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
					labelWidth={70}
				/>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<Button variant="contained" color="primary" onClick={handleClickSubmit}>
					Submit
</Button>
			</FormControl>

		</div>
	);
}

const enhance = connect(
    (state) => {
        return { isFetching: state.auth.isFetching, error: state.auth.error }
    },
    { signInSubmitRequest }
)

export default enhance(Signin);