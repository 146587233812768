import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { reopenCaseRequest } from '../../../actions/cases/reopenCase';

function ReopenCaseView(props) {
    const caseId = props.caseId;
    const handleClickOpen = () => {
        props.reopenCaseRequest({caseId});
    };
    return (
        <div>
            {props.isFetching && <CircularProgress size={24} />}
            {!props.isFetching && <Button disabled={!caseId}  variant="contained" color="primary" onClick={handleClickOpen}>
                ReOpen Case
            </Button>}
        </div>
    );
}
const enhance = connect(
    (state) => {
        return { reopenCaseSuccess: state.reopenCase.result, isFetching: state.reopenCase.isFetching, error: state.reopenCase.error }
    },
    { reopenCaseRequest }
)

export default enhance(ReopenCaseView);
