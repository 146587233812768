import { createAction } from 'redux-actions';

 
  export const reopenCaseRequest = createAction(
    'REOPEN_CASE_REQUEST',
    values => values
  );
  
  export const reopenCaseSuccess = createAction(
    'REOPEN_CASE_SUCCESS',
    values => values
  );

  export const reopenCaseFailure = createAction(
    'REOPEN_CASE_FAILURE',
    values => values
  );
  
  
  