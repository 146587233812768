import { createAction } from 'redux-actions';

 
  export const changeProviderRequest = createAction(
    'CHANGE_PROVIDER_REQUEST',
    values => values
  );
  
  export const changeProviderSuccess = createAction(
    'CHANGE_PROVIDER_SUCCESS',
    values => values
  );

  export const changeProviderFailure = createAction(
    'CHANGE_PROVIDER_FAILURE',
    values => values
  );
  
  
  