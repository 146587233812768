import MomentUtils from '@date-io/moment';
import { Button, Paper, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { getPatientsRequest } from '../../../actions/patients';
import {
	mbiRegExp,
	MBI_VAL_ERROR
} from '../../../utils/mbi_validation';


const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		flexGrow: 1,
		maxWidth: 240
	},
}));

function PatientSearch(props) {
	const classes = useStyles();
	const [values, setValues] = React.useState({
		firstName: '',
		userId: '',
		lastName: '',
		medicareNumber: '',
		birthDate: ''
	});
	const [errors, setErrors] = React.useState({
		firstName: '',
		lastName: '',
		medicareNumber: '',
		birthDate: ''
	});
	const formValid = (values.medicareNumber && !errors.medicareNumber) || values.userId || values.firstName || values.lastName || values.birthDate
	const [selectedDate, setSelectedDate] = React.useState(null);
	const handleDateChange = date => {
		if (date) {
			setSelectedDate(date.format('MM/DD/YYYY'));
			setValues({ ...values, birthDate: date.format('MM/DD/YYYY') });
		} else {
			setValues({ ...values, birthDate: '' });
		}

	};

	const handleChange = (prop) => (event) => {
		if (prop === 'medicareNumber') {
			const validMbi = mbiRegExp.test(event.target.value)
			if (!validMbi) {
				setErrors({ ...errors, [prop]: MBI_VAL_ERROR });
			} else {
				setErrors({ ...errors, [prop]: '' });
			}
			setValues({ ...values, [prop]: event.target.value });
		} if (prop === 'userId') {
			setValues({ ...values, [prop]: event.target.value });
		} else {
			setValues({ ...values, [prop]: event.target.value, medicareNumber: '', userId: '' });

		}
	};


	const handleClickSubmit = () => {
		console.log(values)
		props.getPatientsRequest(values)
	};


	return (
		<Paper className={classes.root}>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<TextField
					value={values.userId}
					onChange={handleChange('userId')}
					error={false}
					id="userId-text"
					label="User Id"
					helperText=""
				/>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<TextField
					value={values.firstName}
					onChange={handleChange('firstName')}
					error={false}
					id="firstname-text"
					label="First Name"
					helperText=""
				/>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<TextField
					value={values.lastName}
					onChange={handleChange('lastName')}
					error={false}
					id="lastname-text"
					label="Last Name"
					helperText=""
				/>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<MuiPickersUtilsProvider utils={MomentUtils}>

					<KeyboardDatePicker
						disableFuture
						autoOk={true}
						margin="normal"
						variant="inline"
						format="MM/DD/yyyy"
						label="Date of birth ( mm/dd/yyyy )"
						value={selectedDate}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</MuiPickersUtilsProvider>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<TextField
					value={values.medicareNumber}
					onChange={handleChange('medicareNumber')}
					error={!!errors.medicareNumber}
					id="medicare-text"
					label="Medicare Number"
					helperText={errors.medicareNumber}
				/>
			</FormControl>
			<FormControl variant="outlined">
				<Button disabled={!formValid} startIcon={props.isFetching ? <CircularProgress size={20} /> : <SearchIcon />} variant="outlined" color="primary" onClick={handleClickSubmit}>
					Search
</Button>
			</FormControl>

		</Paper>
	);
}

const enhance = connect(
	(state) => {
		return { isFetching: state.patients.isFetching, error: state.patients.error }
	},
	{ getPatientsRequest }
)

export default enhance(PatientSearch);