import auth from './auth';
import cases from './cases';
import patientDetails from './patientDetails';
import patients from './patients';
import providers from './providers';
import campaign from './campaign';
export default {
  auth,
  providers,
  patients,
  patientDetails,
  ...cases,
  ...campaign
};
