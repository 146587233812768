/* @flow */
import _ from 'lodash';
import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { getPatientDetailsFailure, getPatientDetailsRequest, getPatientDetailsSuccess } from '../actions/patients';
import { failedRequestPattern, succeededRequestPattern } from '../utils/request';
import { request } from './request';



export function* getPatientDetailsSucceeded(url: string): Iterable<*> {
  const action = yield take(succeededRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getPatientDetailsSuccess(data));
}

export function* getPatientDetailsFailed(url: string): Iterable<*> {
  const action = yield take(failedRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getPatientDetailsFailure(data || 'unable to load patient details'));
}

export function* getPatientDetailsRequestSaga({ payload:patientId }) {
const url = `/user/v2/patient/${patientId}`;
yield fork(
  request,
  url
);

  yield race({
    success: call(getPatientDetailsSucceeded, url),
    fail: call(getPatientDetailsFailed, url)
  });
}

export default function* getPatientDetailsSaga(): Iterator<*> {
  yield takeEvery(String(getPatientDetailsRequest), getPatientDetailsRequestSaga);
}
