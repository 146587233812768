/* @flow */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { getCampaignsRequest } from "../../../actions/campaign";
import { withRouter } from "react-router";
import { Box, Card, Paper, Typography, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

const CampaignList = (props: Object) => {
  const classes = useStyles();
  const { getCampaigns, getCampaignsRequest, match } = props;
  const campaignId = match.params.campaignId;

  const { result: campaigns, isFetching, error } = getCampaigns;
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState(campaignId);

  useEffect(() => {
    getCampaignsRequest();
  }, []);
  const goToCampaignDetails = (campaignId) => {
    setSelectedIndex(campaignId);
    history.push(`/campaign/${campaignId}`);
  };
  const handleCreateCampaign = () => {
    history.push(`/campaign/create`);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} minHeight="100%">
      <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'}>
        <Typography variant="h6"> Campaigns</Typography>
        <IconButton color="primary"  onClick={handleCreateCampaign}>
          <AddCircleIcon />
        </IconButton>
      </Box>
      {campaigns.map((campaign) => (
        <Box
          mt={2}
          key={campaign.campaignId}
          style={{ cursor: "pointer" }}
          onClick={() => goToCampaignDetails(campaign.campaignId)}
        >
          <Card elevation={selectedIndex === campaign.campaignId ? 3 : 1}>
            <Box p={2}>
              <Typography>{campaign.campaignName}</Typography>
            </Box>
          </Card>
        </Box>
      ))}
    </Box>
  );
};

const mapStateToProps = ({ getCampaigns }) => ({ getCampaigns });

const mapDispatchToProps = { getCampaignsRequest };

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(withRouter(CampaignList));
