import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { updateCaseCount } from '../../actions/cases/caseCount';
import { updateCaseDetails } from '../../actions/cases/cases';
import { reopenCaseFailure, reopenCaseRequest, reopenCaseSuccess } from '../../actions/cases/reopenCase';
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../../utils/request';
import { request } from '../request';


export function* handleReopenCaseSuccess(url: string, method: string): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  yield put(reopenCaseSuccess(response.data));
  yield put(updateCaseDetails(response.data));
  yield put(updateCaseCount({to:response.data.status, from:'closed'}));
}

export function* handleReopenCaseFail(url: string, method: string): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  const {  message = "" } = response || {}
  yield put(reopenCaseFailure(message));
}

export function* reopenCaseRequestHandler(
  { payload: {caseId } }
) {
  const method = 'PUT';
  const url = `/case/v2/case/${caseId}/reopen`;
  yield fork(
    request,
    url,
    method
  );

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleReopenCaseSuccess, url, method),
    fail: call(handleReopenCaseFail, url, method)
  });
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export default function* patientSaga() {
  yield takeEvery(reopenCaseRequest, reopenCaseRequestHandler)

}