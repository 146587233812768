import { handleActions } from 'redux-actions';
import { getCaseCountFailure, getCaseCountRequest, getCaseCountSuccess, updateCaseCount } from '../../actions/cases/caseCount';

export default handleActions({
  [getCaseCountRequest]: (state) => {
    return Object.assign({}, state, { isFetching: true, error: false })
  },
  [getCaseCountSuccess]: (state, action) => {
    return Object.assign({}, state, { isFetching: false, error: false, caseCount: action.payload })
  },
  [getCaseCountFailure]: (state, action) => Object.assign({}, state, { isFetching: false, error: action.payload }),
  [updateCaseCount]: (state, action) => {
    let existingcaseCont = Object.assign({}, state.caseCount)
    console.log(action.payload)

    switch (action.payload.to) {
      case 'assigned': {
        existingcaseCont.assigned = parseInt(existingcaseCont.assigned) + 1
        existingcaseCont.pending = parseInt(existingcaseCont.pending) - 1
        break;
      }
      case 'pending':
        existingcaseCont.pending = parseInt(existingcaseCont.pending) + 1
        if(action.payload.from === 'closed'){
          existingcaseCont.closed = parseInt(existingcaseCont.closed) - 1
        } else {
          existingcaseCont.assigned = parseInt(existingcaseCont.assigned) - 1
        }
        break;
      case 'closed':
        existingcaseCont.closed = parseInt(existingcaseCont.closed) + 1
        existingcaseCont.assigned = parseInt(existingcaseCont.pending) - 1
        break;
      default:
        break;
        
    }
    return Object.assign({}, state, { caseCount: existingcaseCont })
  }
}, { caseCount: null, isFetching: null, error: null });