import { createAction } from 'redux-actions';

 
  export const getCasesRequest = createAction(
    'GET_CASES_REQUEST',
    values => values
  );
  
  export const getCasesSuccess = createAction(
    'GET_CASES_SUCCESS',
    values => values
  );

  export const getCasesFailure = createAction(
    'GET_CASES_FAILURE',
    values => values
  );
  export const updateCaseDetails = createAction(
    'UPDATE_CASE_DETAILS',
    values => values
  );

  
  
  