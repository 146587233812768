import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import App from '../pages/app';
import Cases from '../pages/cases';
import Login from '../pages/login';
import PatientCases from '../pages/patientCases';
import Patients from '../pages/patients';
import ProviderCases from '../pages/providerCases';
import Providers from '../pages/providers';
import Campaign from '../pages/campaign';
import CampaignDetails from '../pages/campaignDetails';
import CampaignCreate from '../pages/campaignCreate';

function AuthIsLoaded({ children }) {
  return children
}
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.auth.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
function PublicRoute({ children, ...rest }) {
  const auth = useSelector(state => state.auth.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
// Setup react-redux so that connect HOC can be used
function Routes(props) {
  return (
    <BrowserRouter>
      <AuthIsLoaded>
        <Switch>
        <Route exact path="/" component={() => <Redirect to="/cases" />}/>
          <PublicRoute path="/login">
            {/* Component containing a login which redirects
              to /protected. NOTE: Not included in example */}
            <Login />
          </PublicRoute>
          <PrivateRoute exact path="/cases">
            <App>
              <Cases />
            </App>

          </PrivateRoute>
     
          <PrivateRoute exact path="/providers">
            <App>
              <Providers />
            </App>
          </PrivateRoute>
          <PrivateRoute path="/providers/:providerId/cases">
            <App>
              <ProviderCases />
            </App>
          </PrivateRoute>
          <PrivateRoute exact path="/patients">
            <App>
              <Patients />
            </App>
          </PrivateRoute>
          <PrivateRoute path="/patients/:patientId/cases">
            <App>
              <PatientCases />
            </App>
          </PrivateRoute>
          {/* Campaign Routes */}
          <PrivateRoute path="/campaign/create">
            <App>
              <CampaignCreate />
            </App>
          </PrivateRoute>
          <PrivateRoute path="/campaign/:campaignId">
            <App>
              <CampaignDetails />
            </App>
          </PrivateRoute>
          
          <PrivateRoute path="/campaign">
            <App>
              <Campaign />
            </App>
          </PrivateRoute>
        </Switch>
      </AuthIsLoaded>
    </BrowserRouter>
  )
}


export default Routes