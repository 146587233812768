import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    active: {
        backgroundColor: 'lightgrey'
    },
    detailsRow: {
        margin: '8px 0'
    }
}));

const ProviderDetails = ({ provider }) => {
    const classes = useStyles();
    return   <Paper className={classes.paper} >
            <Typography component="h1" variant="body1">
                <b>Provider Details</b>
            </Typography>
            <Divider light style={{ margin: '8px 0' }} />

            <Box display='flex' flexGrow='1' flexDirection='column' justifyContent='flex-start' alignItems='stretch' >
                <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
                <TextField
                        fullWidth
                        label="User Id"
                        value={provider.userId}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    /> 
                    <TextField
                        fullWidth
                        label="First Name"
                        value={provider.firstName}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Last Name"
                        value={provider.lastName}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                <TextField
                        fullWidth
                        label="Gender"
                        value={provider.gender}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Country & State"
                        value={`${provider.country?.toUpperCase() || 'NA'} - ${provider.state}`}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />

                </Box>
                <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
                    <TextField
                        fullWidth
                        label="Board Certifications"
                        value={provider.boardCertifications}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Medical License"
                        value={provider.medicalLicense}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="State Licensed"
                        value={provider.stateLicensed}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="NPI Number"
                        value={provider.npiNumber}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Speciality"
                        value={provider.speciality}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                    />
                </Box>
            </Box >
        </Paper>
}

const enhance = connect(
    (state, props) => {
        return { provider: state.providers.providers.filter(providerItem => providerItem.userId === props.providerId)[0] }
    }
)

export default enhance(ProviderDetails);
