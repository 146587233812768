import moment from 'moment'
export const getVisitName = (visitType) => {
    const visitTypes = {
        ov: 'Medical Visit',
        mv: 'Medical Visit',
        mhs: 'Mental Health Support',
        wv: 'Wellness Visit',
        nv: 'Nutrition Visit',
        iwv: 'Initial Wellness Visit',
        cva: 'COVID-19 Assessment',
    }
    if (visitType) {
        return visitTypes[visitType]
    } else {
        return 'NA'
    }
}
export const getOpenDateSince = (openDate) => {
    return openDate? moment(new Date(openDate)).utc().fromNow(): 'NA'
}
export const getDateInString = (date) => {
    return date ? moment(new Date(date)).utc().format('MM/DD/YYYY'):'NA'
}
export const getName = (user) => {
    if (user) {
        const { firstName, lastName, middleName } = user;
        return `${firstName || ''} ${middleName || ''} ${lastName || ''}`
    } else{
        return 'NA'
    }

}
export const getScheduleType = (caseDetails) => {
    
   return caseDetails.isScheduled ? 'Scheduled' : 'On Demand'

}
export const toCamelCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);

 
 }