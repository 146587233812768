import { createAction } from 'redux-actions';

export const deleteCampaignUsersRequest: any= createAction(
'DELETE_CAMPAIGN_USERS_REQUEST',
values => values
);

export const deleteCampaignUsersSuccess: any = createAction(
'DELETE_CAMPAIGN_USERS_SUCCESS',
values => values
);

export const deleteCampaignUsersFailure: any= createAction(
'DELETE_CAMPAIGN_USERS_FAILURE',
values => values
);

export const deleteCampaignUsersReset: any = createAction(
'DELETE_CAMPAIGN_USERS_RESET',
values => values
);