/* @flow */
import _ from 'lodash';
import { call, fork, put, race, take, takeLatest } from 'redux-saga/effects';
import { getCasesFailure, getCasesRequest, getCasesSuccess } from '../../actions/cases/cases';
import { failedRequestPattern, succeededRequestPattern } from '../../utils/request';
import { request } from '../request';



export function* getCasesSucceeded(url: string): Iterable<*> {
  const action = yield take(succeededRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getCasesSuccess(data));
}

export function* getCasesFailed(url: string): Iterable<*> {
  const action = yield take(failedRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getCasesFailure(data || 'unable to load case Count'));
}

export function* getCasesRequestSaga({ payload }): Iterable<*> {
  const { providerId, memberId, caseStatus, dateRange } = payload
  let url = `/case/v2/case/${caseStatus}`;
  if (providerId) {
    url = `/case/v2/case/${caseStatus}?providerId=${providerId}`;
  } else if (memberId) {
    url = `/case/v2/case/${caseStatus}?memberId=${memberId}`;
  }
  if (dateRange?.startDate) {
    url = `${url}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  }

  yield fork(request, url);

  yield race({
    success: call(getCasesSucceeded, url),
    fail: call(getCasesFailed, url)
  });
}

export default function* loadServiceSaga(): Iterator<*> {
  yield takeLatest(String(getCasesRequest), getCasesRequestSaga);
}
