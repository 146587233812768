import { createAction } from 'redux-actions';
  export const getPatientsRequest = createAction(
    'GET_PPATIENTS_REQUEST',
    values => values
  );
  
  export const getPatientsSuccess = createAction(
    'GET_PPATIENTS_SUCCESS',
    values => values
  );

  export const getPatientsFailure = createAction(
    'GET_PPATIENTS_FAILURE',
    values => values
  );

  
  export const getPatientDetailsRequest = createAction(
    'GET_PPATIENT_DETAILS_REQUEST',
    values => values
  );
  
  export const getPatientDetailsSuccess = createAction(
    'GET_PPATIENT_DETAILS_SUCCESS',
    values => values
  );

  export const getPatientDetailsFailure = createAction(
    'GET_PPATIENT_DETAILS_FAILURE',
    values => values
  );

  
  