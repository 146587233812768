import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CaseCount, Cases } from '../../Cases';

const useStyles = makeStyles((theme) => ({
    container: {
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function AllCases({ providerId, memberId, dateRange }) {
    const classes = useStyles();
    const defaultCaseStatus = providerId ? 'assigned' : 'pending'
    const [caseStatus, setCaseStatus] = React.useState(defaultCaseStatus);
    return (
        <Container maxWidth={false}  >
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} md={12} lg={12}>
                    <CaseCount setCaseStatus={setCaseStatus} caseStatus={caseStatus} providerId={providerId} memberId={memberId} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Cases caseStatus={caseStatus} providerId={providerId} memberId={memberId} dateRange={dateRange} />
                </Grid>
            </Grid>
        </Container>
    );
}