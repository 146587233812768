/* @flow */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Paper, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router";
import {
  addCampaignUsersRequest,
  addCampaignUsersReset,
  getCampaignDetailsRequest,
  getCampaignUsersRequest
} from "../../../actions/campaign";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexGrow: 1,
    maxWidth: "100%",
  },
}));

const AddCampaignUsersForm = (props: Object) => {
  const classes = useStyles();
  const history = useHistory();
  const {campaignId, addCampaignUsers, addCampaignUsersRequest, addCampaignUsersReset, getCampaignDetailsRequest,
    getCampaignUsersRequest} = props
  console.log("campaignId",campaignId)
  const [usersFile, setUsersFile] = React.useState();
  const { result, isFetching, error } = addCampaignUsers;

  const handleChange = (event) => {
      setUsersFile(event.target.files[0]);
  };

  const handleClickSubmit = () => {
    addCampaignUsersRequest({campaignId, usersFile});
  };

  useEffect(() => {
    if (result) {
      // history.push(`/campaign/${result.campaignId}`);
      getCampaignDetailsRequest({campaignId})
      getCampaignUsersRequest({campaignId})
    }
    return () => addCampaignUsersReset();
  }, [result]);
  return (
    <Paper className={classes.root}>
      <input
        onChange={handleChange}
        error={false}
        type="file"
        id="membersFile"
        label="Members File"
      />
      <br></br>
      <Button
        startIcon={props.isFetching && <CircularProgress size={20} />}
        variant="contained"
        color="primary"
        onClick={handleClickSubmit}
      >
        Submit
      </Button>
    </Paper>
  );
};

const mapStateToProps = ({ addCampaignUsers }) => ({ addCampaignUsers });

const mapDispatchToProps = { addCampaignUsersRequest, addCampaignUsersReset, getCampaignDetailsRequest,
  getCampaignUsersRequest };

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(AddCampaignUsersForm);
