import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    container: {
        // marginBottom: theme.spacing(4),
    },
    formControl: {
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
}));

function CaseCount({ setCaseStatus, caseStatus, providerId, memberId }) {
    const classes = useStyles();
    return (
        <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={4}>
            <FormControl  margin="normal" className={classes.formControl}>
                <InputLabel id="case-status-selector-label">Filter By Status</InputLabel>
                <Select
                    labelId="case-status-selector-label"
                    id="case-status-selector"
                    value={caseStatus}
                    onChange={(event)=>{setCaseStatus(event.target.value)}}
                >
                    {!providerId && <MenuItem value={'pending'}>Pending</MenuItem>}
                    <MenuItem value={'assigned'}>Assigned</MenuItem>
                    <MenuItem value={'closed'}>Closed</MenuItem>
                </Select>
            </FormControl>
            </Grid></Grid>
    );
}

export default CaseCount
