import { createAction } from 'redux-actions';

export const deleteCampaignRequest: any= createAction(
'DELETE_CAMPAIGN_REQUEST',
values => values
);

export const deleteCampaignSuccess: any = createAction(
'DELETE_CAMPAIGN_SUCCESS',
values => values
);

export const deleteCampaignFailure: any= createAction(
'DELETE_CAMPAIGN_FAILURE',
values => values
);

export const deleteCampaignReset: any = createAction(
'DELETE_CAMPAIGN_RESET',
values => values
);