import { take, put, call, fork, race, takeLatest } from "redux-saga/effects";

import {
  getCampaignDetailsRequest,
  getCampaignDetailsSuccess,
  getCampaignDetailsFailure,
} from "../../actions/campaign";

import formUrlencoded from "form-urlencoded";
import { request } from "../request";

/* @flow */
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../../utils/request';
export function* handleGetCampaignDetailsSuccess(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  yield put(getCampaignDetailsSuccess(response.data));
}

export function* handleGetCampaignDetailsFailure(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  const { message = "" } = response || {};
  yield put(getCampaignDetailsFailure(message));
}

export function* getCampaignDetailsRequestHandler({
  payload: { campaignId },
}: {
  payload: { campaignId: any },
}): Iterable<*> {
  const method = "GET";
  const url = `/pinpoint/v1/campaign/${campaignId}`;
  yield fork(request, url, method);

  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleGetCampaignDetailsSuccess, url, method),
    fail: call(handleGetCampaignDetailsFailure, url, method),
  });
}

export default function* getCampaignDetailsSaga() {
  yield takeLatest(getCampaignDetailsRequest, getCampaignDetailsRequestHandler);
}
