/* @flow */
import { handleActions } from "redux-actions";
import {
getCampaignUsersRequest,
getCampaignUsersSuccess,
getCampaignUsersFailure,
getCampaignUsersReset,
} from "../../../actions/campaign";

export default handleActions(
{
[getCampaignUsersReset]: () => ({ result: null, isFetching: null, error: null }),
[getCampaignUsersRequest]: (state) => {
return Object.assign({}, state, { isFetching: true, error: null });
},
[getCampaignUsersSuccess]: (state, action) =>
Object.assign({}, state, { isFetching: false, result: action.payload, error: null }),
[getCampaignUsersFailure]: (state, action) =>
Object.assign({}, state, { isFetching: false, error: action.payload, result:null }),
},
{ result: [], isFetching: null, error: null }
);