import { all } from 'redux-saga/effects';
import getCampaigns from "./getCampaigns";
import getCampaignDetails from "./getCampaignDetails";
import createCampaign from "./createCampaign";
import deleteCampaign from "./deleteCampaign";
import getCampaignUsers from "./getCampaignUsers";
import syncCampaignUsers from "./syncCampaignUsers";
import addCampaignUsers from "./addCampaignUsers";
import deleteCampaignUsers from "./deleteCampaignUsers";

export default function* saga() {
  yield all([
     getCampaigns(),
     getCampaignDetails(),
     createCampaign(),
     deleteCampaign(),
     getCampaignUsers(),
     syncCampaignUsers(),
     addCampaignUsers(),
     deleteCampaignUsers(),
  ]);
}
