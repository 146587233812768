import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { changeProviderRequest } from '../../../actions/cases/changeProvider';
import ProvidersList from '../../ProvidersList';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ChangeProviderView(props) {
    const hideProviderId = props.hideProviderId
    const [open, setOpen] = React.useState(false);
    const [providerId, setProviderId] = React.useState(null);
    const caseId = props.caseId;

    useEffect(() => {
        if (props.changeProviderSuccess) {
            handleClose()
        }
    }, [props.changeProviderSuccess])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const setSelectedProviderId = (providerId) => {
        providerId && setProviderId(providerId)
    };

    const handleClickSubmit = () => {
        console.log(caseId, providerId)
        props.changeProviderRequest({ caseId, providerId })
    };


    return (
        <div>
            <Button disabled={!caseId} variant="contained" color="primary" onClick={handleClickOpen}>
                Change Provider
						</Button>
            <Dialog fullWidth={true} maxWidth={'xs'} onClose={handleClose} aria-labelledby="update-phone-dialog-title" open={open}>
                <DialogTitle id="update-phone-dialog-title" onClose={handleClose}>
                    Select Provider To Change
        </DialogTitle>
                <DialogContent dividers>
                    <ProvidersList onCloseIframe={handleClose} setSelectedProviderCallback={setSelectedProviderId} hideProviderId={hideProviderId} />
                </DialogContent>
                <DialogActions>
                    {props.isFetching && <CircularProgress size={24} />}
                    {!props.isFetching && <Button variant="contained" color="primary" disabled={!providerId || props.isFetching} onClick={handleClickSubmit}>
                        Submit
					</Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
const enhance = connect(
    (state) => {
        return { changeProviderSuccess: state.changeProvider.result, isFetching: state.changeProvider.isFetching, error: state.changeProvider.error }
    },
    { changeProviderRequest }
)

export default enhance(ChangeProviderView);
