import { green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProvidersRequest } from '../../actions/providers';
const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        flexGrow: 1,
        marginBottom: 16
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        flexDirection: 'column'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}));

function ProvidersList({ providers, getProvidersRequest, setSelectedProviderCallback, hideProviderId }) {
    const classes = useStyles();
    const [selectedProviderId, setSelectedProviderId] = React.useState(null);

    useEffect(() => {
        getProvidersRequest()
    }, [getProvidersRequest])


    const handleListItemClick = (userId) => {
        setSelectedProviderId(userId);
        setSelectedProviderCallback && setSelectedProviderCallback(userId)
    };


    return (
        <div className={classes.paper}>
            <List component="nav" aria-label="main mailbox folders">
                {providers.filter(provider => provider.userId !== hideProviderId).map((provider, index) =>
                    <div key={index}>
                        <ListItem
                            key={index}
                            button
                            selected={selectedProviderId === provider.userId}
                            onClick={() => handleListItemClick(provider.userId)}>
                            <ListItemText primary={provider.fullName} />

                        </ListItem>
                        {index !== providers.length - 1 && <Divider component="li" />}
                    </div>
                )}
            </List>
        </div>
    );
}

const enhance = connect(
    (state) => {
        return { isFetching: state.providers.isFetching, error: state.providers.error, providers: state.providers.providers }
    },
    { getProvidersRequest }
)

export default enhance(ProvidersList);