import { all } from 'redux-saga/effects';
import authSaga from './auth';
import casesSaga from './cases';
import patientDetailsSaga from './patientDetails';
import patientsSaga from './patients';
import providersSaga from './providers';
import campaignSaga from './campaign';

export default function* saga() {
  yield all([
    authSaga(),
    casesSaga(),
    providersSaga(),
    patientsSaga(),
    patientDetailsSaga(),
    campaignSaga()
  ]);
}
