import { handleActions } from 'redux-actions';
import { getCasesFailure, getCasesRequest, getCasesSuccess, updateCaseDetails } from '../../actions/cases/cases';

export default handleActions({
  [getCasesRequest]: (state) => {
    return Object.assign({}, state, { isFetching: true, error: false })
  },
  [getCasesSuccess]: (state, action) => {
    return Object.assign({}, state, { isFetching: false, error: false, cases: action.payload })
  },
  [getCasesFailure]: (state, action) => Object.assign({}, state, { isFetching: false, error: action.payload }),
  [updateCaseDetails]: (state, action) => {
    const existingCases = state.cases
    let newCases = existingCases.filter(item => {
      return item.caseId !== action.payload.caseId;
    })
    newCases.push(action.payload)
    return Object.assign({}, state, { cases: newCases })
  }
}, { cases: [], isFetching: null, error: null });