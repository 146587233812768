import { take, put, call, fork, race, takeLatest } from "redux-saga/effects";

import {
  createCampaignRequest,
  createCampaignSuccess,
  createCampaignFailure,
} from "../../actions/campaign";

import formUrlencoded from "form-urlencoded";
import { request } from "../request";

/* @flow */
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../../utils/request';
export function* handleCreateCampaignSuccess(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  yield put(createCampaignSuccess(response.data));
}

export function* handleCreateCampaignFailure(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  const { message = "" } = response || {};
  yield put(createCampaignFailure(message));
}

export function* createCampaignRequestHandler({
  payload: campaign,
}: {
  payload: any,
}): Iterable<*> {
  const method = "POST";
  const url = "/pinpoint/v1/campaign";

  yield fork(request, url, method, {
    contentType: 'application/json',
    body: JSON.stringify(campaign),
  }); 
  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleCreateCampaignSuccess, url, method),
    fail: call(handleCreateCampaignFailure, url, method),
  });
}

export default function* createCampaignSaga() {
  yield takeLatest(createCampaignRequest, createCampaignRequestHandler);
}
