/* @flow */
import formUrlencoded from 'form-urlencoded';
import _ from 'lodash';
import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { getPatientsFailure, getPatientsRequest, getPatientsSuccess } from '../actions/patients';
import { failedRequestPattern, succeededRequestPattern } from '../utils/request';
import { request } from './request';



export function* getPatientsSucceeded(url: string, method:String): Iterable<*> {
  const action = yield take(succeededRequestPattern(url,method));
  const data = _.get(action, 'payload.response.data');
  yield put(getPatientsSuccess(data));
}

export function* getPatientsFailed(url: string,method:String): Iterable<*> {
  const action = yield take(failedRequestPattern(url,method));
  const data = _.get(action, 'payload.response.data');
  yield put(getPatientsFailure(data || 'unable to load plans'));
}

export function* getPatientsRequestSaga({ payload: {
  firstName,
  userId,
  lastName,
  birthDate,
  medicareNumber } }
) {
const method = 'POST';
const url = '/user/v2/user/patients';
yield fork(
  request,
  url,
  method,
  {
    body: formUrlencoded({
      firstName,
      lastName,
      birthDate,
      userId,
      medicareNumber: medicareNumber && medicareNumber.replace(/-/g, "")
    })
  }
);

  yield race({
    success: call(getPatientsSucceeded, url,method),
    fail: call(getPatientsFailed, url,method)
  });
}

export default function* loadServiceSaga(): Iterator<*> {
  yield takeEvery(String(getPatientsRequest), getPatientsRequestSaga);
}
