import formUrlencoded from 'form-urlencoded';
import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { updateCaseCount } from '../../actions/cases/caseCount';
import { updateCaseDetails } from '../../actions/cases/cases';
import { changeProviderFailure, changeProviderRequest, changeProviderSuccess } from '../../actions/cases/changeProvider';
import {
  failedRequestPattern, startRequestPattern,
  succeededRequestPattern
} from '../../utils/request';
import { request } from '../request';




export function* handleChangeProviderSuccess(url: string, method: string): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  yield put(changeProviderSuccess(response.data));
  yield put(updateCaseDetails(response.data));
  yield put(updateCaseCount({to:response.data.status, from:'assigned'}));
  
}

export function* handleChangeProviderFail(url: string, method: string): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const { payload: { response } }: { payload: { response: Object } } = action;
  const {  message = "" } = response || {}
  yield put(changeProviderFailure(message));
}

export function* changeProviderRequestHandler(
  { payload: {caseId, providerId } }
) {
  const method = 'PUT';
  const url = `/case/v2/case/${caseId}/reassignprovider`;
  yield fork(
    request,
    url,
    method,
    {
      body: formUrlencoded({
        providerId,
      })
    }
  );


  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleChangeProviderSuccess, url, method),
    fail: call(handleChangeProviderFail, url, method)
  });
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export default function* patientSaga() {
  yield takeEvery(changeProviderRequest, changeProviderRequestHandler)

}