import { createAction } from 'redux-actions';
  export const getProvidersRequest = createAction(
    'GET_PROVIDERS_REQUEST',
    values => values
  );
  
  export const getProvidersSuccess = createAction(
    'GET_PROVIDERS_SUCCESS',
    values => values
  );

  export const getProvidersFailure = createAction(
    'GET_PROVIDERS_FAILURE',
    values => values
  );
  
