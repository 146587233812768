import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import reducers from '../reducers'
import sagas from '../sagas'

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
  whitelist: ['auth','providers'],
}
 
const rootReducer = combineReducers({
  ...reducers,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware() // create middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Create store with reducers and initial state
const initialState = {}
const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)))
let persistor = persistStore(store)
sagaMiddleware.run(sagas)
export { store, persistor }
