/* @flow */
import React, { useEffect } from "react";
import {
  makeStyles,
  MuiThemeProvider,
  unstable_createMuiStrictModeTheme,
} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { getCampaignUsersRequest } from "../../../actions/campaign";
import { Box, Button, Card, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { getDateInString } from "../../../utils/caseDetailsFormat";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const getMuiTheme = () =>
  unstable_createMuiStrictModeTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
    },
  });

const CampaignUsersList = (props: Object) => {
  const classes = useStyles();
  const { campaignId, getCampaignUsers, getCampaignUsersRequest } = props;
  const { result: campaignUsers, isFetching, error } = getCampaignUsers;
  useEffect(() => {
    getCampaignUsersRequest({ campaignId });
  }, [campaignId]);
  const columns = [
    { label: "Member", name: "memberId" },
    { label: "Insurance", name: "insuranceName" },
    { label: "First Name", name: "firstName" },
    { label: "Last Name", name: "lastName" },
    {
      label: "Birth Date",
      name: "birthDate",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return getDateInString(getDateForTable()[dataIndex].birthDate);
        },
      },
    },
    { label: "Provider Name", name: "providerName" },
    { label: "Accepted", name: "campaignAccepted" },
  ];

  const getDateForTable = () => {
    return campaignUsers.map((row) => {
      return {
        insuranceName: row.insuranceName,
        memberId: row.memberId,
        firstName: row.firstName,
        lastName: row.lastName,
        birthDate: row.dob,
        campaignAccepted: row.campaignAccepted?'Yes':'',
        providerName: row.providerName,
      };
    });
  };
  const options = {
    selectableRows: "single",
    rowsPerPage: 10,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "memberId",
      direction: "desc",
    },
  };
  if (!campaignUsers.length) {
    return <Box>{isFetching?'Loading...':'No Members Found'}</Box>;
  }
  return (
    <Paper className={classes.root}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={`Campaign Members`}
          data={getDateForTable()}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Paper>
  );
};

const mapStateToProps = ({ getCampaignUsers }) => ({ getCampaignUsers });

const mapDispatchToProps = { getCampaignUsersRequest };

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(CampaignUsersList);
