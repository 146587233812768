import getCampaigns from './handlers/getCampaigns';
import getCampaignDetails from './handlers/getCampaignDetails';
import createCampaign from './handlers/createCampaign';
import deleteCampaign from './handlers/deleteCampaign';
import getCampaignUsers from './handlers/getCampaignUsers';
import syncCampaignUsers from './syncCampaignUsers';
import addCampaignUsers from './addCampaignUsers';
import deleteCampaignUsers from './deleteCampaignUsers';

export default {
  getCampaigns,
  getCampaignDetails,
  createCampaign,
  deleteCampaign,
  getCampaignUsers,
  syncCampaignUsers,
  addCampaignUsers,
  deleteCampaignUsers
};
