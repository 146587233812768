import { handleActions } from 'redux-actions';
import { assignProviderFailure, assignProviderRequest, assignProviderSuccess } from '../../actions/cases/assignProvider';

export default handleActions({
  [assignProviderRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [assignProviderSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, result: action.payload })
  },
  [assignProviderFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload }),
}, { result: null, isFetching: null, error: null });