import { take, put, call, fork, race, takeLatest } from "redux-saga/effects";

import {
  addCampaignUsersRequest,
  addCampaignUsersSuccess,
  addCampaignUsersFailure,
} from "../../actions/campaign";

import formUrlencoded from "form-urlencoded";
import { request } from "../request";

/* @flow */
import {
  startRequestPattern,
  succeededRequestPattern,
  failedRequestPattern,
} from "../../utils/request";

export function* handleAddCampaignUsersSuccess(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(succeededRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  yield put(addCampaignUsersSuccess(response.data));
}

export function* handleAddCampaignUsersFailure(
  url: string,
  method: string
): Iterable<*> {
  const action: any = yield take(failedRequestPattern(url, method));
  const {
    payload: { response },
  }: { payload: { response: Object } } = action;
  const { message = "" } = response || {};
  yield put(addCampaignUsersFailure(message));
}

export function* addCampaignUsersRequestHandler({
  payload: { usersFile, campaignId },
}: {
  payload: { usersFile: any, campaignId: string },
}): Iterable<*> {
  const method = "POST";
  const url = `/pinpoint/v1/campaign/${campaignId}/users`;
  const formData = new FormData();
  formData.append("file", usersFile);

  yield fork(request, url, method, {
    contentType: "file",
    body: formData,
  });
  yield take(startRequestPattern(url, method));

  yield race({
    success: call(handleAddCampaignUsersSuccess, url, method),
    fail: call(handleAddCampaignUsersFailure, url, method),
  });
}

export default function* addCampaignUsersSaga() {
  yield takeLatest(addCampaignUsersRequest, addCampaignUsersRequestHandler);
}
