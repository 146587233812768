import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCaseCountRequest } from '../../../actions/cases/caseCount';
const useStyles = makeStyles((theme) => ({
    container: {
        // marginBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
    },
    active: {
        border: '1px solid #7acc75'
    }
}));

function CaseCount({ caseCount, isFetching, getCaseCountRequest, setCaseStatus, caseStatus, providerId, memberId }) {
    const classes = useStyles();
    useEffect(() => {
        getCaseCountRequest({ providerId, memberId })
    }, [getCaseCountRequest, providerId, memberId])
    const { pending = 0, assigned = 0, closed = 0, cosign=0 } = caseCount || {}
    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={classnames(classes.paper, { [classes.active]: caseStatus === 'pending' })} onClick={() => setCaseStatus('pending')}>
                        <Typography component="h1" variant="h6"> Pending </Typography>
                        <Divider light style={{ margin: '8px 0' }} />
                        <Typography component="h1" variant="h4">
                            {pending}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={classnames(classes.paper, { [classes.active]: caseStatus === 'assigned' })} onClick={() => setCaseStatus('assigned')}>
                        <Typography component="h1" variant="h6">  Assigned  </Typography>
                        <Divider light style={{ margin: '8px 0' }} />
                        <Typography component="h1" variant="h4">
                            {assigned}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={classnames(classes.paper, { [classes.active]: caseStatus === 'closed' })} onClick={() => setCaseStatus('closed')}>
                        <Typography component="h1" variant="h6"> Closed </Typography>
                        <Divider light style={{ margin: '8px 0' }} />
                        <Typography component="h1" variant="h4">
                            {closed}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={classnames(classes.paper, { [classes.active]: caseStatus === 'cosign' })} onClick={() => setCaseStatus('cosign')}>
                        <Typography component="h1" variant="h6"> Cosign </Typography>
                        <Divider light style={{ margin: '8px 0' }} />
                        <Typography component="h1" variant="h4">
                            {cosign}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
const enhance = connect(
    (state) => {
        return { caseCount: state.casesCount.caseCount, isFetching: state.casesCount.isFetching }
    },
    { getCaseCountRequest }
)

export default enhance(CaseCount);
