import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from "react-router";
import ProviderCases from '../components/Providers/ProviderCases';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  }
}));

function ProviderCasesView({ match }) {
  const classes = useStyles();
  const providerId = match.params.providerId
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <ProviderCases providerId={providerId} />
      </main>
    </div>
  );
}

export default withRouter(ProviderCasesView);