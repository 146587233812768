import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import React from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import NavBar from '../components/NavBar';
import SendIcon from '@material-ui/icons/Send';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function App({ children, location }) {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className={classes.root}>
            <CssBaseline />
            <NavBar />

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar variant="dense" />
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItem button key={'Cases'} selected={location.pathname === '/cases'} onClick={() => history.push("/cases")}>
                            <ListItemIcon><ListIcon /></ListItemIcon>
                            <ListItemText primary={'Cases'} />
                        </ListItem>
                        <ListItem button key={'Providers'} selected={location.pathname.includes('/providers')}  onClick={() => history.push("/providers")}>
                            <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
                            <ListItemText primary={'Providers'} />
                        </ListItem>
                        <ListItem button key={'Patients'} selected={location.pathname.includes('/patients')} onClick={() => history.push("/patients")}>
                            <ListItemIcon><PeopleIcon /></ListItemIcon>
                            <ListItemText primary={'Patients'} />
                        </ListItem>
                        <ListItem button key={'Campaign'} selected={location.pathname.includes('/campaign')} onClick={() => history.push("/campaign")}>
                            <ListItemIcon><SendIcon /></ListItemIcon>
                            <ListItemText primary={'Campaign'} />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    );
}

export default withRouter(App);