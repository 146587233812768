import React from 'react';
export  function Logo(props) {
	return (
		<img className={props.styles} alt='logo' src={require("./logo.png")}></img>
	);
}
export  function LogoIcon(props) {
	return (
		<img className={props.styles }alt='logo' src={require("./logo-icon.png")}></img>
	);
}
export default {Logo,LogoIcon}