/* @flow */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Paper, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router";
import {
  createCampaignRequest,
  createCampaignReset,
} from "../../../actions/campaign";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexGrow: 1,
    maxWidth: "100%",
  },
}));

const CreateCampaignForm = (props: Object) => {
  const classes = useStyles();
  const history = useHistory();
  const { createCampaign, createCampaignRequest, createCampaignReset } = props;
  const { result, isFetching, error } = createCampaign;
  
  const [values, setValues] = React.useState({
    campaignName: "",
    campaignId: "",
    inviteId: "",
  });
  
  const [errors, setErrors] = React.useState({
    campaignName: "",
    campaignId: "",
    inviteId: "",
  });

  const formValid = values.campaignName || values.campaignId || values.inviteId;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickSubmit = () => {
    createCampaignRequest(values);
  };

  useEffect(() => {
    if (result) {
      history.push(`/campaign/${result.campaignId}`);
    }
    return () => createCampaignReset();
  }, [result]);
  return (
    <Paper className={classes.root}>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <TextField
          value={values.userId}
          onChange={handleChange("campaignName")}
          error={false}
          id="campaign_name"
          label="Name"
          variant="outlined"
          helperText=""
        />
      </FormControl>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <TextField
          value={values.firstName}
          onChange={handleChange("campaignId")}
          error={false}
          id="campaign_id"
          variant="outlined"
          label="Id"
          helperText=""
        />
      </FormControl>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <TextField
          value={values.lastName}
          onChange={handleChange("inviteId")}
          error={false}
          id="inviteId"
          label="Invite Id"
          helperText=""
          variant="outlined"
        />
      </FormControl>
      {/* <FormControl
        className={clsx(classes.margin, classes.textField)}
      >
        <input
          value={values.lastName}
          onChange={handleChange('membersFile')}
          error={false}
          type="file"
          id="membersFile"
          label="Members File"
        />
      </FormControl> */}
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <Button
          disabled={!formValid}
          startIcon={props.isFetching && <CircularProgress size={20} />}
          variant="contained"
          color="primary"
          onClick={handleClickSubmit}
        >
          Submit
        </Button>
      </FormControl>
    </Paper>
  );
};

const mapStateToProps = ({ createCampaign }) => ({ createCampaign });

const mapDispatchToProps = { createCampaignRequest, createCampaignReset };

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(CreateCampaignForm);
