import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  addCampaignUsersRequest,
  addCampaignUsersReset,
} from "../../../actions/campaign";
import AddCampaignUsersForm from '../AddCampaignUsersForm'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AddCampaignUsers(props) {
  const {
    campaignId,
    addCampaignUsers,
    disabled
  } = props;
  const { result, isFetching, error } = addCampaignUsers;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (result) {
      handleClose();
    }
  }, [result]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box width={'100%'}>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        disabled={disabled}
        onClick={handleClickOpen}
      >
        Add Users
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleClose}
        aria-labelledby="update-phone-dialog-title"
        open={open}
      >
        <DialogTitle id="update-phone-dialog-title" onClose={handleClose}>
          Add Users
        </DialogTitle>
        <DialogContent dividers>
          <AddCampaignUsersForm campaignId={campaignId}/>
        </DialogContent>
        {/* <DialogActions>
          {props.isFetching && <CircularProgress size={24} />}
        </DialogActions> */}
      </Dialog>
    </Box>
  );
}

const mapStateToProps = ({ addCampaignUsers }) => ({
  addCampaignUsers,
});

const mapDispatchToProps = {
  addCampaignUsersRequest,
  addCampaignUsersReset,
};
const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(AddCampaignUsers);
