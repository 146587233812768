import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getDateInString, getVisitName } from '../../../utils/caseDetailsFormat';
import AssignProvider from '../AssignProvider';
import ChangeProvider from '../ChangeProvider';
import ReopenCase from '../ReopenCase';
import UnassignProvider from '../UnassignProvider';
const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        height: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
    },
    active: {
        backgroundColor: 'lightgrey'
    },
    detailsRow: {
        margin: '8px 0'
    }
}));
const PatientDetails = (patient, classes) => {
    if (!patient) {
        return <div>Not Available</div>
    }

    return <Box display='flex' flexGrow='1' flexDirection='column' justifyContent='flex-start' alignItems='stretch' >
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label=" First Name"
                value={patient.firstName}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />

            <TextField
                fullWidth
                label="Last Name"
                value={patient.lastName}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Gender"
                value={patient.gender}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="Birth Date"
                value={getDateInString(patient.birthDate)}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Country"
                value={patient.country}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="State"
                value={patient.state}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
    </Box>
}
const ProviderDetails = (provider, classes) => {
    if (!provider) {
        return <div>Not Assigned</div>
    }

    return <Box display='flex' flexGrow='1' flexDirection='column' justifyContent='flex-start' alignItems='stretch' >
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="First Name"
                value={provider.firstName}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="Last Name"
                value={provider.lastName}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Country"
                value={provider.country}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="State"
                value={provider.state}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Board Certifications"
                value={provider.boardCertifications}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="Medical License"
                value={provider.medicalLicense}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="State Licensed"
                value={provider.stateLicensed}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="NPI Number"
                value={provider.npiNumber}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Speciality"
                value={provider.speciality}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box >
    </Box >
}
const CaseDetailsView = (caseDetails, classes) => {
    if (!caseDetails) {
        return <div>Not Assigned</div>
    }
    return <Box display='flex' flexGrow='1' flexDirection='column' justifyContent='flex-start' alignItems='stretch' >

        <TextField
            fullWidth
            label="Name"
            className={classes.detailsRow}
            value={caseDetails.name}
            InputProps={{
                readOnly: true,
                disableUnderline: true
            }}
        />
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Case Id"
                value={caseDetails.caseId}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />

            <TextField
                fullWidth
                label="Status"
                value={caseDetails.status}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Visit Type"
                value={caseDetails.isScheduled ? 'Scheduled' : 'On Demand'}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                fullWidth
                label="Case Type"
                value={getVisitName(caseDetails.visitType)}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' className={classes.detailsRow} >
            <TextField
                fullWidth
                label="Open Date"
                value={getDateInString(caseDetails.openDate)}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            {caseDetails.status === 'pending' && caseDetails.closeDate && <TextField
                fullWidth
                label="Close Date"
                value={getDateInString(caseDetails.closeDate)}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />}
        </Box>
    </Box>
}
function CaseDetails({ caseDetails }) {
    const classes = useStyles();
    const caseStatus = caseDetails.status
    const history = useHistory();
    const openPatient = (patientId) => {
        history.push(`/patients/${patientId}/cases`)
    };
    const openProvider = (providerId) => {
        history.push(`/providers/${providerId}/cases`)
    };
    return (
        <Container maxWidth="md" className={classes.container}>
            <Toolbar variant="dense" />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={classes.paper} >
                                <Typography component="h1" variant="body1">
                                    <b>Case Details</b>
                                </Typography>
                                <Divider light style={{ margin: '8px 0' }} />
                                {CaseDetailsView(caseDetails, classes)}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={classes.paper} >
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography component="h1" variant="body1">
                                        <b>Patient Details</b>
                                    </Typography>
                                    <Button  size="small"  disabled={!caseDetails.member} endIcon={<OpenInNewIcon />} color="primary" onClick={() => openPatient(caseDetails.member.userId)}>
                                        {'Open'}

                                    </Button>
                                </Box>
                                <Divider light style={{ margin: '8px 0' }} />
                                {PatientDetails(caseDetails.member, classes)}

                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={classes.paper} >
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography component="h1" variant="body1">
                                        <b>Provider Details</b>
                                    </Typography>
                                    <Button   variant="text" disabled={!caseDetails.provider} endIcon={<OpenInNewIcon />}  color="primary" onClick={() => openProvider(caseDetails.provider.userId)}>
                                        {'Open'}

                                    </Button>
                                </Box>
                                <Divider light style={{ margin: '8px 0' }} />
                                {ProviderDetails(caseDetails.provider, classes)}


                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={classes.paper} >
                                <Typography component="h1" variant="body1">
                                    <b>Options</b>
                                </Typography>
                                <Divider light style={{ margin: '8px 0' }} />
                                <Box display='flex' flexGrow='1' flexDirection='column' justifyContent='flex-start' alignItems='stretch'>
                                    {caseStatus === 'closed' && <div style={{ margin: 8 }}>  <ReopenCase caseId={caseDetails.caseId} /></div>}
                                    {/* {caseStatus === 'assigned' && <div style={{ margin: 8 }}><OpenChat caseId={caseDetails.caseId} /></div>} */}
                                    {caseStatus === 'pending' && <div style={{ margin: 8 }}><AssignProvider caseId={caseDetails.caseId} /></div>}
                                    {['assigned', 'cosign'].includes(caseStatus) && <div style={{ margin: 8 }}>  <ChangeProvider caseId={caseDetails.caseId} hideProviderId={caseDetails.provider.userId} /></div>}
                                    {['assigned', 'cosign'].includes(caseStatus) && <div style={{ margin: 8 }}> <UnassignProvider caseId={caseDetails.caseId} /></div>}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
const enhance = connect(
    (state, props) => {
        return { caseDetails: state.cases.cases.filter(caseItem => caseItem.caseId === props.caseId)[0] }
    }
)

export default enhance(CaseDetails);
