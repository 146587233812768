import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PatientSearch from '../PatientSearch';
import PatientsTable from '../PatientsTable';
const useStyles = makeStyles((theme) => ({
    container: {
    }
}));

export default function PatientList({ patients }) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <PatientSearch />
            <PatientsTable/>
        </Container>
    );
}
