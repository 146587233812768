import { handleActions } from 'redux-actions';
import { getProvidersFailure, getProvidersRequest, getProvidersSuccess } from '../actions/providers';

export default handleActions({
  [getProvidersRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [getProvidersSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, providers: action.payload })
  },
  [getProvidersFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload , providers:[]}),
}, { providers: [], isFetching: null, error: null });