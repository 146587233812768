import { createAction } from 'redux-actions';
  export const getCaseCountRequest = createAction(
    'GET_CASE_COUNT_REQUEST',
    values => values
  );
  
  export const getCaseCountSuccess = createAction(
    'GET_CASE_COUNT_SUCCESS',
    values => values
  );

  export const getCaseCountFailure = createAction(
    'GET_CASE_COUNT_FAILURE',
    values => values
  );
  export const updateCaseCount = createAction(
    'UDPATE_CASE_COUNT',
    values => values
  );