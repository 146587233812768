import { indigo } from '@material-ui/core/colors';
import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import Routes from './routes';
import { persistor, store } from './store';
const theme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: indigo[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});
function App() {
  return (
    <Provider store={store}>
       <PersistGate loading={<div>Loading</div>} persistor={persistor}>
    <ThemeProvider theme={theme}>
      <Routes />
      </ThemeProvider>
      </PersistGate>
  </Provider>
  );
}

export default App;
