/* @flow */
import _ from 'lodash';
import { call, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { getProvidersFailure, getProvidersRequest, getProvidersSuccess } from '../actions/providers';
import { failedRequestPattern, succeededRequestPattern } from '../utils/request';
import { request } from './request';



export function* getProvidersSucceeded(url: string): Iterable<*> {
  const action = yield take(succeededRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  console.log(data)
  yield put(getProvidersSuccess(data));
}

export function* getProvidersFailed(url: string): Iterable<*> {
  const action = yield take(failedRequestPattern(url));
  const data = _.get(action, 'payload.response.data');
  yield put(getProvidersFailure(data || 'unable to load plans'));
}

export function* getProvidersRequestSaga(): Iterable<*> {
  const url = '/user/v2/user/providers';
  yield fork(request, url);

  yield race({
    success: call(getProvidersSucceeded, url),
    fail: call(getProvidersFailed, url)
  });
}

export default function* loadServiceSaga(): Iterator<*> {
  yield takeEvery(String(getProvidersRequest), getProvidersRequestSaga);
}
