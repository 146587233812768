import Paper from '@material-ui/core/Paper';
import { makeStyles, MuiThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import React, { createRef, useEffect } from 'react';
import { getDateInString } from '../../../utils/caseDetailsFormat';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const getMuiTheme = () => unstable_createMuiStrictModeTheme({
    overrides: {
        MUIDataTableBodyRow: {
            root: {
                cursor: 'pointer'
            }
        }
    }
})

export default function StickyHeadTable({ providers, caseStatus, onClickRow }) {
    const classes = useStyles();

    const tableRef = createRef();
    useEffect(() => {
        if (caseStatus) {
            tableRef.current.changePage(0);
        }
    }, [caseStatus, tableRef])


    const columns = [
        { label: 'UserId', name: 'userId' },
        { label: 'Full Name', name: 'fullName' },
        { label: 'Gender', name: 'gender' },
        {
            label: 'Birth Date', name: 'birthDate', options: {
                customBodyRenderLite: (dataIndex) => {
                    return getDateInString(getDateForTable()[dataIndex].birthDate)
                }
            }
        },
        { label: 'Board Certifications', name: 'boardCertifications' },
        { label: 'City', name: 'city' },
        { label: 'Country', name: 'country' },
        { label: 'Dea Number', name: 'deaNumber' },
        { label: 'Location', name: 'location' },
        { label: 'Medical License', name: 'medicalLicense' },
        { label: 'NPI Number', name: 'npiNumber' },
        { label: 'Speciality', name: 'speciality' },
        { label: 'State', name: 'state' },
        { label: 'State Licensed', name: 'stateLicensed' },
        { label: 'ZipCode', name: 'zipCode' },

    ];

    const getDateForTable = () => {
        return providers.map((row) => {
            return {
                userId: row.userId,
                fullName: row.fullName,
                gender: row.gender,
                birthDate: row.birthDate,
                boardCertifications: row.boardCertifications,
                city: row.city,
                country: row.country,
                deaNumber: row.deaNumber,
                location: row.location,
                medicalLicense: row.medicalLicense,
                npiNumber: row.npiNumber,
                speciality: row.speciality,
                state: row.state,
                stateLicensed: row.stateLicensed,
                zipCode: row.zipCode,
            }
        })
    }
    const options = {
        selectableRows: 'single',
        rowsPerPage: 10,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        sortOrder: {
            name: 'userId',
            direction: 'desc',
        },
        onRowClick: (rowData) => {
            onClickRow(true, rowData[0])
        }
    };
    return (
        <Paper className={classes.root}>
            <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                innerRef={tableRef}
                title={`Providers`}
                data={getDateForTable()}
                columns={columns}
                options={options}
            />
            </MuiThemeProvider>
        </Paper>
    );
}
