import { createAction } from 'redux-actions';

export const getCampaignDetailsRequest: any= createAction(
'GET_CAMPAIGN_DETAILS_REQUEST',
values => values
);

export const getCampaignDetailsSuccess: any = createAction(
'GET_CAMPAIGN_DETAILS_SUCCESS',
values => values
);

export const getCampaignDetailsFailure: any= createAction(
'GET_CAMPAIGN_DETAILS_FAILURE',
values => values
);

export const getCampaignDetailsReset: any = createAction(
'GET_CAMPAIGN_DETAILS_RESET',
values => values
);