/* @flow */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getCampaignDetailsRequest,
  deleteCampaignRequest,
  syncCampaignUsersRequest,
  deleteCampaignUsersRequest,
  deleteCampaignReset,
  getCampaignUsersRequest,
  syncCampaignUsersReset,
  deleteCampaignUsersReset,
} from "../../../actions/campaign";
import { useHistory } from "react-router";
import { Box, Button, Card, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import AddCampaignUsers from "../AddCampaignUsers";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

const CampaignDetails = (props: Object) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    campaignId,
    getCampaignDetails,
    getCampaignDetailsRequest,
    deleteCampaignRequest,
    deleteCampaign,
    syncCampaignUsers,
    syncCampaignUsersRequest,
    deleteCampaignUsers,
    deleteCampaignUsersRequest,
    deleteCampaignReset,
    getCampaignUsersRequest,
    syncCampaignUsersReset,
    deleteCampaignUsersReset,
  } = props;
  const { result: campaign, isFetching, error } = getCampaignDetails;
  const {
    result: deleteCampaignResult,
    isFetching: isDeleting,
    error: deleteError,
  } = deleteCampaign;
  const {
    result: syncCampaignResult,
    isFetching: isSyncing,
    error: syncCampaignError,
  } = syncCampaignUsers;
  const {
    result: deleteCampaignUsersResult,
    isFetching: isDeleteingUSers,
    error: deleteCampaignUsersError,
  } = deleteCampaignUsers;
  useEffect(() => {
    if (syncCampaignResult) {
      getCampaignDetailsRequest({ campaignId });
      syncCampaignUsersReset();
    }
  }, [syncCampaignResult]);
  useEffect(() => {
    if (deleteCampaignUsersResult) {
      getCampaignDetailsRequest({ campaignId });
      getCampaignUsersRequest({ campaignId });
      deleteCampaignUsersReset();
    }
  }, [deleteCampaignUsersResult]);

  useEffect(() => {
    getCampaignDetailsRequest({ campaignId });
    getCampaignUsersRequest({ campaignId });
  }, [campaignId]);

  useEffect(() => {
    if (deleteCampaignResult) {
      history.push("/campaign");
    }
    return () => deleteCampaignReset();
  }, [deleteCampaignResult]);

  if (!campaign) {
    return <Box>Loading</Box>;
  }
  const addUsersHandler = () => {};
  const deleteUsersHandler = () => {
    deleteCampaignUsersRequest({ campaignId });
  };
  const syncUsersHandler = () => {
    syncCampaignUsersRequest({ campaignId });
  };
  const deleteCampaignHandler = () => {
    deleteCampaignRequest({ campaignId });
  };
  return (
    <Box display="flex" justifyContent="space-between">
      <Box p={2} display="flex" alignItems="flex-start" flexDirection="column">
        <Typography>
          <b>Name:</b> {campaign.campaignName}
        </Typography>
        <Typography>
          <b>Id:</b> {campaign.campaignId}
        </Typography>
        <Typography>
          <b>Total Users:</b> {campaign.totalUsersCount}
        </Typography>
        <Typography>
          <b>Accepted Users:</b> {campaign.acceptedUsersCount}
        </Typography>
      </Box>
      <Box p={2} display="flex" flexDirection="column">
        {/* <Button variant="outlined" color="primarty" onClick={addUsersHandler}>
          Add Users
        </Button> */}
        <AddCampaignUsers
          campaignId={campaignId}
          disabled={campaign.isPinpointSync || campaign.totalUsersCount != 0}
        />
        <br></br>
        <Button
          variant="outlined"
          color="primarty"
          onClick={deleteUsersHandler}
          disabled={campaign.isPinpointSync || campaign.totalUsersCount == 0}
        >
          Delete Users
        </Button>
        <br></br>
        <Button variant="outlined" color="primarty" onClick={syncUsersHandler}   disabled={campaign.isPinpointSync || campaign.totalUsersCount == 0}>
          Sync to Pinpoint
        </Button>
        <br></br>
        <Button
          variant="outlined"
          color="primarty"
          onClick={deleteCampaignHandler}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({
  getCampaignDetails,
  deleteCampaign,
  syncCampaignUsers,
  deleteCampaignUsers,
}) => ({
  getCampaignDetails,
  deleteCampaign,
  syncCampaignUsers,
  deleteCampaignUsers,
});

const mapDispatchToProps = {
  getCampaignDetailsRequest,
  deleteCampaignRequest,
  syncCampaignUsersRequest,
  deleteCampaignUsersRequest,
  deleteCampaignReset,
  getCampaignUsersRequest,
  syncCampaignUsersReset,
  deleteCampaignUsersReset,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(CampaignDetails);
