import { handleActions } from 'redux-actions';
import { reopenCaseFailure, reopenCaseRequest, reopenCaseSuccess } from '../../actions/cases/reopenCase';

export default handleActions({
  [reopenCaseRequest]: (state) => {
    return Object.assign({},state, { isFetching: true, error: false})
  },
  [reopenCaseSuccess]: (state, action) => {
    return Object.assign({},state, { isFetching: false, error: false, result: action.payload })
  },
  [reopenCaseFailure]: (state, action) => Object.assign({},state, { isFetching: false, error: action.payload }),
}, { result: null, isFetching: null, error: null });